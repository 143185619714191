@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700;800&family=Poppins:wght@100;200;300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700&display=swap&family=Inter:wght@400;500;600;700;800&display=swap");

/*------Color Pallet------*/
$theme: #0bab47;
$primary-light: white;
$primary-dark: #091f2c;
$dark-two: #334f5f;
$dark-three: #69808d;
$dark-four: #becad6;
$dark-five: #f2f6f9;
$dark-six: #f2f8fd;
$dark-seven: #f9fbff;

$font_layout1: "Inter", "Roboto", "sans-serif", Arial, Helvetica;

/*------Mixins------*/
@mixin font($fntFam, $fntSize, $fntW, $lineH, $fontStyle) {
  font-family: $fntFam !important;
  font-size: $fntSize;
  font-weight: $fntW !important;
  line-height: $lineH;
  font-style: $fontStyle;
}
@mixin btnStyle($bg, $color) {
  background-color: $bg;
  color: $color;
}
/*------Placeholder------*/
%buttonMain {
  display: flex;
  justify-content: center;
  color: $primary-light;
  font-weight: 700;
  border-radius: 4px;
  transition: 0.2s all;
  cursor: pointer;
  &:is(a) {
    padding: 13.58px 14px 12.42px 12px;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: $font_layout1, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
h1 {
  @include font($font_layout1, 56px, 700, 67.2px, "normal");
}
h2 {
  @include font($font_layout1, 48px, 700, 57.6px, "normal");
}
h3 {
  @include font($font_layout1, 36px, 600, 43.2px, "normal");
}
h4 {
  @include font($font_layout1, 22px, 700, 26.4px, "normal");
}

.emphasis_para {
  @include font($font_layout1, 18px, 400, 25.2px, "normal");
}

.finePrint_para {
  @include font($font_layout1, 11px, 400, 13.31px, "normal");
}

p {
  @include font($font_layout1, 16px, 400, 24px, "normal");
}
a,
h5,
h6 {
  font-family: $font_layout1;
}

:any-link:hover:not(
    #options,
    #navLinks,
    .primary-btn,
    .secondary-btn-sml,
    .primary-btn-sml
  ) {
  color: $theme !important;
  transition: 0.2s all;
}
:focus-visible {
  outline: none;
}

::selection {
  background: $theme;
  color: $primary-light;
}
::-moz-selection {
  background: $theme;
  color: $primary-light;
}

.primary-btn:is(a, button) {
  @extend %buttonMain;
  width: 180px;
  height: 50px;
  font-size: 16px;
  line-height: 18.75px;
  border: none !important;
  background-color: $theme;
  &:hover {
    @include btnStyle(#0a9648, none);
  }
}

.secondary-btn:is(a, button) {
  @extend %buttonMain;
  width: 160px;
  height: 50px;
  font-size: 16px;
  line-height: 18.75px;
  border: 1px solid $primary-light !important;
  &:hover {
    @include btnStyle($primary-light, $primary-dark);
  }
}

.primary-btn-sml:is(a, button) {
  @extend %buttonMain;
  width: 133px;
  height: 43px;
  font-size: 14px;
  line-height: 16.41px;
  border: none !important;
  background-color: $theme;
  &:hover {
    @include btnStyle(#0a9648, none);
  }
}

.secondary-btn-sml:is(a, button) {
  @extend %buttonMain;
  width: 133px;
  height: 43px;
  font-size: 14px;
  line-height: 16.41px;
  border: 1px solid $primary-light !important;
  &:hover {
    @include btnStyle($primary-light, $primary-dark);
  }
}

@media (max-width: 620px) {
  .primary-btn:is(a, button) {
    width: 100%;
  }

  .secondary-btn:is(a, button) {
    width: 100%;
  }
}
