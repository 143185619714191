@import "index.scss";
//general
.main {
  min-height: 100vh;
}
.container {
  margin: 0 auto;
}
//hero
.App-hero {
  background: rgba(9, 31, 44, 0.3);
}
.App-hero_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: $primary-light;
}
//navbar
.header {
  background: linear-gradient(
    180deg,
    rgba(9, 31, 44, 0.6) 0%,
    rgba(9, 31, 44, 0) 94.79%
  );
}
nav {
  background-color: transparent !important;
}
//footer
footer {
  background-color: $primary-dark !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: $primary-light !important;
}

footer li {
  margin-top: 0 !important;
  color: $primary-light !important;
  font-size: 0.9rem !important;
}

footer h2 {
  margin-bottom: 1rem !important;
  color: $primary-light !important;
  font-weight: 700 !important;
}

a[data-testid="flowbite-footer-brand"] img {
  width: 180px;
  height: auto;
}

.footer-content {
  @include font("Poppins", 12px, 400, 18px, "normal");
}

.copy-right {
  @include font("Poppins", 12px, 400, 18px, "normal");
}
footer h2[data-testid="flowbite-footer-title"] {
  color: #cfd1d5 !important;
}

footer .divider {
  border-top: 1px solid #cfd1d557;
  margin-top: 30px;
  margin-bottom: 27px;
  width: 100%;
}
//section
.section_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: $primary-dark;
}
//card
.cardItem div[data-testid="flowbite-card"] {
  border: none;
  border-radius: 8px !important;
  box-shadow: none !important;
  height: 100%;
}

.lastStep div[data-testid="flowbite-card"] {
  border: none;
  border-radius: 8px !important;
  box-shadow: none !important;
  height: 100%;
  background-color: $theme;
  color: $primary-light;
  align-items: center;
  text-align: center;
  width: 283px;
  height: 220px;
}
//icon group
.iconGroupItem {
  margin-top: 30px;
  text-align: center;
}
.iconGroupItem p {
  @include font($font_layout1, 18px, 700, 21.6px, "normal");
  margin-top: 8px;
}
.iconGroupItem .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: $dark-six;
  width: 54px !important;
  height: 54px !important;
  border-radius: 8px;
}
.iconGroupItem .icon img {
  width: 32px !important;
  height: 32px !important;
}
//expanded cta
.expandedCta {
  background-color: $primary-dark;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 45px;
}
//Resonsive
@media only screen and (max-width: 639px) {
  .stacked {
    background-color: $primary-dark;
    color: $primary-light;
    border-radius: 8px;
    padding: 2.5rem 1.25rem 2.5rem 1.25rem;
  }
  h1 {
    @include font($font_layout1, 38px, 700, 45.6px, "normal");
  }
  h2 {
    @include font($font_layout1, 34px, 700, 40.8px, "normal");
    max-width: 335px;
  }
  .emphasis_para {
    @include font($font_layout1, 16px, 400, 22.4px, "normal");
  }
}
